var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "login-form login-signin" },
      [
        _vm._m(0),
        _c(
          "b-form",
          {
            staticClass: "form",
            on: {
              submit: function ($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.onSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "alert fade alert-danger",
                class: { show: _vm.errors.length },
                attrs: { role: "alert" },
              },
              _vm._l(_vm.errors, function (error, i) {
                return _c("div", { key: i, staticClass: "alert-text" }, [
                  _vm._v(" " + _vm._s(error) + " "),
                ])
              }),
              0
            ),
            _c(
              "b-form-group",
              {
                attrs: {
                  id: "example-input-group-1",
                  label: "",
                  "label-for": "example-input-1",
                },
              },
              [
                _c("b-form-input", {
                  staticClass:
                    "form-control form-control-solid h-auto py-5 px-6",
                  attrs: {
                    id: "example-input-1",
                    name: "example-input-1",
                    state: _vm.validateState("username"),
                    placeholder: "Username",
                    "aria-describedby": "input-1-live-feedback",
                  },
                  model: {
                    value: _vm.$v.form.username.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.form.username, "$model", $$v)
                    },
                    expression: "$v.form.username.$model",
                  },
                }),
                _c(
                  "b-form-invalid-feedback",
                  { attrs: { id: "input-1-live-feedback" } },
                  [_vm._v(" Username is required. ")]
                ),
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                attrs: {
                  id: "example-input-group-2",
                  label: "",
                  "label-for": "example-input-2",
                },
              },
              [
                _c("b-form-input", {
                  staticClass:
                    "form-control form-control-solid h-auto py-5 px-6",
                  attrs: {
                    type: "password",
                    id: "example-input-2",
                    name: "example-input-2",
                    placeholder: "Password",
                    state: _vm.validateState("password"),
                    "aria-describedby": "input-2-live-feedback",
                  },
                  model: {
                    value: _vm.$v.form.password.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.form.password, "$model", $$v)
                    },
                    expression: "$v.form.password.$model",
                  },
                }),
                _c(
                  "b-form-invalid-feedback",
                  { attrs: { id: "input-2-live-feedback" } },
                  [_vm._v(" Password is required. ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "form-group d-flex flex-wrap justify-content-between align-items-center",
              },
              [
                _c(
                  "button",
                  {
                    ref: "kt_login_signin_submit",
                    staticClass:
                      "btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3",
                  },
                  [_vm._v(" Sign In ")]
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center mb-10 mb-lg-20" }, [
      _c("h3", { staticClass: "font-size-h1" }, [_vm._v("Sign In")]),
      _c("p", { staticClass: "text-muted font-weight-semi-bold" }, [
        _vm._v(" Enter your username and password "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }